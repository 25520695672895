import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export function Hero({
  image,
  alt,
  header,
  firstButtonLabel,
  firstButtonLink,
  highlightFirstButton,
  secondButtonLabel,
  secondButtonLink,
  highlightSecondButton,
}) {
  return (
    <div className="h-96 relative font-sans -z-50">
      <div className="absolute w-full h-full">
        {image && (
          <GatsbyImage
            className="h-full w-full object-cover"
            image={image}
            alt={alt}
            loading="eager"
          />
        )}
      </div>
      <div className="relative h-full flex">
        <div className="flex justify-center w-full h-full backdrop-filter backdrop-grayscale">
          <div className="m-4 p-4 self-center bg-black bg-opacity-80 space-y-4">
            <h1 className="font-sans m-0 tracking-wide text-3xl sm:text-5xl text-center text-white">
              {header}
            </h1>
            {firstButtonLabel &&
              <div className="flex flex-col md:flex-row justify-center md:space-x-4 space-y-4 md:space-y-0">
                <Link
                  to={firstButtonLink}
                  title={firstButtonLabel}
                  className={`text-center px-6 py-4 rounded-full uppercase ${highlightFirstButton ? "text-gray-900 bg-yellow-400 hover:bg-yellow-500 hover:text-white" : "text-yellow-400 border border-yellow-400 bg-transparent hover:bg-yellow-400 hover:text-gray-900"}`}
                >
                  {firstButtonLabel}
                </Link>
                {secondButtonLabel &&
                  <Link
                    to={secondButtonLink}
                    title={secondButtonLabel}
                    className={`text-center px-6 py-4 rounded-full uppercase ${highlightSecondButton ? "text-gray-900 bg-yellow-400 hover:bg-yellow-500 hover:text-white" : "text-yellow-400 border border-yellow-400 bg-transparent hover:bg-yellow-400 hover:text-gray-900"}`}
                  >
                    {secondButtonLabel}
                  </Link>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
