import * as React from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

const Footer = () => (
  <footer className="bg-gray-100 mt-8">
    <div className="container mx-auto px-4 py-8 flex flex-row items-center">
      <Link to="/" title="Bostonian Electric Homepage">
        <StaticImage
          src="../images/bostonian-electric-logo.png"
          alt="Bostonian Electric logo"
          className="m-4"
          width={150}
        />
      </Link>
      <div className="font-sans">
        © {new Date().getFullYear()}, Bostonian Electric & Control Solutions Inc.
      </div>
    </div>
  </footer>
)

export default Footer
