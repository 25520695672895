import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"

const navLinks = [
  {
    title: "About Us",
    href: "/about-us/",
  },
  {
    title: "Services",
    href: "/services/",
  },
  {
    title: "Projects",
    href: "/projects/",
  },
  {
    title: "Reviews",
    href: "/reviews/",
  },
  {
    title: "Contact Us",
    href: "/contact/",
  },
]

const Header = () => (
  <header className="bg-gray-100">

    <Popover className="relative">
      <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">

        {/* Logo */}
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link to="/">
            <StaticImage
              src="../images/bostonian-electric-logo.png"
              alt="Bostonian Electric logo"
              height={100}
              loading="eager"
            />
          </Link>
        </div>

        {/* Mobile open menu */}
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-gray-100 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gold-500">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>

        {/* Main nav section */}
        <nav className="hidden md:flex space-x-10">
          {navLinks.map(link => (
            <Link
              key={link.title}
              to={link.href}
              className="text-black font-sans italic"
              activeClassName="text-blue-500"
              title={link.title}
            >
              {link.title}
            </Link>
          ))}
        </nav>

      </div>

      {/* Mobile menu panel */}
      <Transition
        as={React.Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50">

          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">

              {/* Mobile top bar with icon and menu toggle */}
              <div className="flex items-center justify-between">
                <StaticImage
                  src="../images/bostonian-electric-logo.png"
                  alt="Bostonian Electric logo"
                  height={100}
                />
                <Popover.Button
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gold-500"
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>

              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {navLinks.map(link => (
                    <Link
                      key={link.title}
                      to={link.href}
                      className="text-black font-sans italic"
                      activeClassName="text-blue-500"
                      title={link.title}
                    >
                      {link.title}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>

    </Popover>
  </header>
)

export default Header
