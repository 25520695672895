import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"
import Footer from "./footer"

const Layout = ({ children }) => (
  <div className="flex flex-col min-h-screen">
    <Header/>
    <main className="flex-grow">
      {children}
    </main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
